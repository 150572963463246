import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import {whiteslitedWallets} from "./redux/data/wallets";
import contractAbi from "./abi.json";
import { ethers, BigNumber } from "ethers";
import Countdown from "react-countdown";
const { MerkleTree } = require('merkletreejs')
const KECCAK256 = require('keccak256')

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 80%;
  cursor: pointer;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--secondary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const ResponsiveWrapperHead = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  justify-content: flex-start;
`;

export const StyledLogo = styled.img`
  width: 20px;
  @media (min-width: 767px) {
    width: 30px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  margin-top: -50%;
  margin-bottom: 10px;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const Completionist = () => <span>SALE STARTED!</span>;
  let isSaleActive = false;
  let isWhitelisted = false;
  let saleName = "";
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click MINT to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  if(data.isActive || data.isWhitelistActive) {
    isSaleActive = true;
  }

  if(whiteslitedWallets.includes(blockchain.account)) {
    isWhitelisted = true;
  }

  if(data.isActive) {
    saleName = "PUBLIC SALE"
  } else if (data.isWhitelistActive) {
    saleName = "WHITELIST SALE"
  } else {
    saleName = "SALE NOT STARTED"
  }

  async function claimNFTs () {
    if (data.isActive) {
      if(window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(
          CONFIG.CONTRACT_ADDRESS,
          contractAbi,
          signer
        );
        try {
          setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
          setClaimingNft(true);
          var price = CONFIG.MINT_PRICE
          const response = await contract.mint(BigNumber.from(mintAmount), { value: ethers.utils.parseEther(((price * mintAmount)).toFixed(4).toString()) })
          const receipt = await response.wait()
          if(receipt) {
            console.log(receipt);
            setFeedback(
              `Congrats! Mint Successful! Please check twitter for further updates and links.`
            );
            setClaimingNft(false);
            dispatch(fetchData(blockchain.account));
          }
        } catch (err) {
          console.log(err);
          setFeedback(err.reason);
          setClaimingNft(false);
        }
      }
    } else if (data.isWhitelistActive) {
        if(window.ethereum) {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const contract = new ethers.Contract(
            CONFIG.CONTRACT_ADDRESS,
            contractAbi,
            signer
          );
          try {
            var price = CONFIG.MINT_PRICE
            setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
            setClaimingNft(true);
            const response = await contract.whitelistMint(BigNumber.from(mintAmount), { value: ethers.utils.parseEther((price).toString()) })
            const receipt = await response.wait()
            if(receipt) {
              console.log(receipt);
              setFeedback(
                `Congrats! Mint Successful! Please check twitter for further updates and links.`
              );
              setClaimingNft(false);
              dispatch(fetchData(blockchain.account));
            }
          } catch (err) {
            console.log(err);
            setFeedback(err.reason);
            setClaimingNft(false);
          }
      } else {
        alert('you are not whitelisted!')
      }
    } else {
      alert('No sale is started yet!')
    }
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    let maxMintAmount = 1;
    if (data.isActive) {
      maxMintAmount = data.publicWalletLimit;
    }
    if (data.isWhitelistActive) {
      maxMintAmount = data.wlWalletLimit;
    }
    if (newMintAmount > maxMintAmount) {
      newMintAmount = maxMintAmount;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >
        <ResponsiveWrapperHead flex={1} style={{ padding: 2 }} test>
          <a href={CONFIG.SCAN_LINK} target="_blank">
            <StyledLogo alt={"logo"} src={"/config/images/etherscan.png"} style={{ padding: 2, backgroundColor: "white" , borderRadius: "100%"}}/>
          </a>
          <s.SpacerSmall />
          <a href={CONFIG.MARKETPLACE_LINK} target="_blank">
            <StyledLogo alt={"logo"} src={"/config/images/opensea.png"} style={{ padding: 2, backgroundColor: "white" , borderRadius: "100%"}}/>
          </a>
          <s.SpacerSmall />
          <a href={CONFIG.TWITTTER_LINK} target="_blank">
            <StyledLogo alt={"logo"} src={"/config/images/twitter.png"} style={{ padding: 2, backgroundColor: "white" , borderRadius: "100%"}}/>
          </a>
        </ResponsiveWrapperHead>
          <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.Container flex={2} jc={"left"} ai={"top"}>
          <s.TextTitle
              style={{ textAlign: "center", fontSize: "30px", color: "var(--secondary-text)", fontWeight: "bold", textShadow: "0 2px 2px white"}}
            >
              Whitelist Mint Info:
            </s.TextTitle>
            <s.SpacerMedium/>
            <s.TextTitle style={{ textAlign: "center", color: "var(--secondary-text)", fontWeight: "bold", textShadow: "0 2px 2px white"}}>
              Total Supply 500
            </s.TextTitle>
            <s.SpacerSmall />
            <s.TextTitle style={{ textAlign: "center", color: "var(--secondary-text)", fontWeight: "bold", textShadow: "0 2px 2px white"}}>
              Hold 6+ can mint 4
            </s.TextTitle>
            <s.TextTitle style={{ textAlign: "center", color: "var(--secondary-text)", fontWeight: "bold", textShadow: "0 2px 2px white"}}>
              Hold 4+ can mint 3
            </s.TextTitle>
            <s.TextTitle style={{ textAlign: "center", color: "var(--secondary-text)", fontWeight: "bold", textShadow: "0 2px 2px white"}}>
              Hold 2+ can mint 1
            </s.TextTitle>
          </s.Container>
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 24,
              borderRadius: 10,
              border: "0px solid var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
              background: "rgba(0,0,0,0.3)",
              backdropFilter: "saturate(180%) blur(6px)",
            }}
          >
            <StyledImg src={"/config/images/PFP.png"}>

            </StyledImg>
            <s.TextTitle
              style={{fontSize: "30px", fontWeight: "bold", textAlign: "center"}}
            >
              {saleName}
            </s.TextTitle>
            <s.SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                {blockchain.account === "" || blockchain.smartContract === null || !isSaleActive || data.wlWalletLimit < 1 ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {(blockchain.account != null)?(data.wlWalletLimit>=1?'You are eligible, you can mint '+data.wlWalletLimit:'You are not holder of atleast 2, you can mint public'):''}
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      {(blockchain.smartContract != null)?'CONNECTED':'CONNECT'}
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {data.wlWalletLimit>=1?'You are eligible, you can mint '+data.wlWalletLimit:'You are not holder of atleast 2, you can mint public'}
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        Total Price: {(mintAmount * CONFIG.MINT_PRICE).toFixed(4)} + Gas
                      </s.TextDescription>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "MINTING..." : "MINT"}
                      </StyledButton>
                    </s.Container>
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 30,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              Supply Status: {data.totalSupply} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={2} jc={"top"} ai={"center"}>
            <s.TextTitle
              style={{ fontSize: "30px", color: "var(--secondary-text)", fontWeight: "bold", textShadow: "0 2px 2px white"}}
            >
              Public Mint Info:
            </s.TextTitle>
            <s.SpacerMedium/>
            <s.TextTitle style={{ textAlign: "center", color: "var(--secondary-text)", fontWeight: "bold", textShadow: "0 2px 2px white"}}>
              Total Supply 500
            </s.TextTitle>
            <s.SpacerSmall />
            <s.TextTitle style={{ textAlign: "center", color: "var(--secondary-text)", fontWeight: "bold", textShadow: "0 2px 2px white"}}>
              2 NFT Per Wallet
            </s.TextTitle>
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerMedium />
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
        <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 30,
                fontWeight: "bold",
                color: "var(--secondary-text)",
              }}
            >
              Sale starting in:-
              <Countdown date={new Date('2023-03-18T11:30:00.896Z').getTime()}>
                <Completionist />
              </Countdown>
            </s.TextTitle>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default App;
